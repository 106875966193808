var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('SubsidiaryMainStyled', [_vm.subsidiary ? _c('Panel', {
    scopedSlots: _vm._u([{
      key: "header",
      fn: function fn() {
        return [_c('router-link', {
          attrs: {
            "to": {
              name: 'organization',
              params: {
                id: _vm.subsidiary.organization.id
              }
            }
          }
        }, [_vm._v(" " + _vm._s(_vm.subsidiary.organization.name) + " ")]), _c('ChevronRightIcon'), _c('span', {
          staticClass: "subsidiary-name",
          on: {
            "click": _vm.activateEditSubsidiaryOverlay
          }
        }, [_c('span', [_vm._v(_vm._s(_vm.subsidiary.name))]), _c('Edit2Icon')], 1)];
      },
      proxy: true
    }, {
      key: "main",
      fn: function fn() {
        return [_c('div', {
          staticClass: "subsidiary-main"
        }, [_c('Subpanel', {
          scopedSlots: _vm._u([{
            key: "header",
            fn: function fn() {
              return [_c('div', {
                staticClass: "title"
              }, [_vm._v(" " + _vm._s(_vm.$tc('setting', 2)) + " ")])];
            },
            proxy: true
          }, {
            key: "main",
            fn: function fn() {
              return [_c('div', {
                staticClass: "settings"
              }, [_c('div', {
                staticClass: "cell option"
              }, [_c('OnOffToggle', {
                staticClass: "on-off-toggle",
                attrs: {
                  "value": _vm.subsidiary.nonAdminUsersCanSeeOtherUsers
                },
                on: {
                  "toggle": function toggle($event) {
                    return _vm.toggleNonAdminUsersCanSeeOtherUsers(_vm.subsidiary);
                  }
                }
              }), _c('span', [_vm._v(_vm._s(_vm.$t('nonAdminUsersCanSeeOtherUsers')))])], 1)])];
            },
            proxy: true
          }], null, false, 1099082484)
        }), _c('Subpanel', {
          scopedSlots: _vm._u([{
            key: "header",
            fn: function fn() {
              return [_c('div', {
                staticClass: "title"
              }, [_vm._v(" " + _vm._s(_vm.$tc('user', 2)) + " ")])];
            },
            proxy: true
          }, {
            key: "main",
            fn: function fn() {
              return [_vm.subsidiaryMemberships.length > 0 ? _c('div', {
                staticClass: "members"
              }, [_c('div', {
                staticClass: "cell username"
              }, [_c('strong', [_vm._v(_vm._s(_vm.$tc('username', 1)))])]), _c('div', {
                staticClass: "cell email"
              }, [_c('strong', [_vm._v(_vm._s(_vm.$tc('email', 1)))])]), _c('div', {
                staticClass: "cell role"
              }, [_c('strong', [_vm._v(_vm._s(_vm.$tc('role', 1)))])]), _c('div', {
                staticClass: "cell spacer"
              }), _vm._l(_vm.subsidiaryMemberships, function (membership) {
                return [_c('div', {
                  key: 'username_' + membership.id,
                  staticClass: "cell username"
                }, [_vm._v(" " + _vm._s(membership.user.username) + " ")]), _c('div', {
                  key: 'email_' + membership.id,
                  staticClass: "cell email"
                }, [_c('nobr', [_vm._v(_vm._s(membership.user.email))])], 1), _c('div', {
                  key: 'role_' + membership.id,
                  staticClass: "cell role"
                }, [_c('BasicSelect', {
                  attrs: {
                    "target": _vm.$tc('role', 1),
                    "options": _vm.roles,
                    "selected": membership.selectedRole
                  },
                  on: {
                    "change": function change($event) {
                      return _vm.setRole($event, membership);
                    }
                  }
                })], 1), _c('div', {
                  key: 'spacer_' + membership.id,
                  staticClass: "cell spacer"
                }, [_c('ButtonRound', {
                  attrs: {
                    "disabled": false
                  },
                  on: {
                    "click": function click($event) {
                      return _vm.confirmDeleteSubsidiaryMembership(membership);
                    }
                  }
                }, [_c('XIcon')], 1), membership.userId === _vm.userId ? _c('BadgeStyled', [_vm._v(" " + _vm._s(_vm.$tc('itIsYou')) + " ")]) : _vm._e()], 1)];
              })], 2) : _c('div', {
                staticClass: "no-members"
              }, [_c('i18n', {
                attrs: {
                  "path": "messages.noDataWithType"
                },
                scopedSlots: _vm._u([{
                  key: "type",
                  fn: function fn() {
                    return [_vm._v(" " + _vm._s(_vm.$tc('member', 2)) + " ")];
                  },
                  proxy: true
                }], null, false, 413023508)
              })], 1)];
            },
            proxy: true
          }, {
            key: "footer",
            fn: function fn() {
              return [_c('div', {
                staticClass: "add-member"
              }, [_c('Multiselect', {
                staticClass: "multiselect",
                attrs: {
                  "track-by": "id",
                  "label": "label",
                  "disabled": !_vm.canAddMember,
                  "placeholder": _vm.$t('actions.add'),
                  "selectLabel": _vm.$t('actions.pressEnterToSelect'),
                  "options": _vm.availableUsers,
                  "searchable": true,
                  "multiple": false,
                  "loading": _vm.$apollo.loading,
                  "internal-search": true,
                  "close-on-select": true,
                  "max-height": 600,
                  "show-no-results": false,
                  "hide-selected": true
                },
                on: {
                  "select": _vm.addMember
                },
                scopedSlots: _vm._u([{
                  key: "noOptions",
                  fn: function fn() {
                    return [_vm._v(" " + _vm._s(_vm.$t('messages.noAdditionalEntitiesAvailable', {
                      entity: _vm.$tc('user', 2)
                    })) + " ")];
                  },
                  proxy: true
                }], null, false, 2070374080)
              })], 1)];
            },
            proxy: true
          }], null, false, 5897070)
        }), _c('Subpanel', {
          scopedSlots: _vm._u([{
            key: "header",
            fn: function fn() {
              return [_c('div', {
                staticClass: "title"
              }, [_vm._v(" " + _vm._s(_vm.$tc('asset', 2)) + " ")])];
            },
            proxy: true
          }, {
            key: "main",
            fn: function fn() {
              return [_c('PasteIds', {
                attrs: {
                  "mode": 'addAssetsToSubsidiary'
                },
                on: {
                  "refetch": _vm.refetch
                }
              }), _vm.subsidiaryAssetsMapped.length > 0 ? _c('div', {
                staticClass: "assets"
              }, [_c('div', {
                staticClass: "cell name"
              }, [_c('strong', [_vm._v(_vm._s(_vm.$tc('name', 1)))])]), _c('div', {
                staticClass: "cell site"
              }, [_c('strong', [_vm._v(_vm._s(_vm.$tc('site', 1)))])]), _c('div', {
                staticClass: "cell spacer"
              }), _vm._l(_vm.subsidiaryAssetsMapped, function (asset) {
                return [_c('div', {
                  key: 'name_' + asset.id,
                  staticClass: "cell name"
                }, [_c('nobr', [_vm._v(_vm._s(asset.name))])], 1), _c('div', {
                  key: 'site_' + asset.id,
                  staticClass: "cell site"
                }, [_c('nobr', [_vm._v(_vm._s(asset.siteName))])], 1), _c('div', {
                  key: 'spacer_' + asset.id,
                  staticClass: "cell spacer"
                }, [_c('ButtonRound', {
                  attrs: {
                    "disabled": false
                  },
                  on: {
                    "click": function click($event) {
                      return _vm.confirmDeleteAsset(asset);
                    }
                  }
                }, [_c('XIcon')], 1)], 1)];
              })], 2) : _c('div', {
                staticClass: "no-assets"
              }, [_c('i18n', {
                attrs: {
                  "path": "messages.noDataWithType"
                },
                scopedSlots: _vm._u([{
                  key: "type",
                  fn: function fn() {
                    return [_vm._v(" " + _vm._s(_vm.$tc('asset', 2)) + " ")];
                  },
                  proxy: true
                }], null, false, 2895573492)
              })], 1)];
            },
            proxy: true
          }, {
            key: "footer",
            fn: function fn() {
              return [_c('div', {
                staticClass: "add-asset"
              }, [_c('Multiselect', {
                staticClass: "multiselect",
                attrs: {
                  "track-by": "id",
                  "label": "label",
                  "disabled": !_vm.canAddAsset,
                  "placeholder": _vm.$t('actions.add'),
                  "selectLabel": _vm.$t('actions.pressEnterToSelect'),
                  "options": _vm.availableAssets,
                  "searchable": true,
                  "multiple": false,
                  "loading": _vm.$apollo.loading,
                  "internal-search": true,
                  "close-on-select": true,
                  "max-height": 600,
                  "show-no-results": false,
                  "hide-selected": true
                },
                on: {
                  "select": _vm.addAsset
                },
                scopedSlots: _vm._u([{
                  key: "noOptions",
                  fn: function fn() {
                    return [_vm._v(" " + _vm._s(_vm.$t('messages.noAdditionalEntitiesAvailable', {
                      entity: _vm.$tc('asset', 2)
                    })) + " ")];
                  },
                  proxy: true
                }], null, false, 1916538625)
              })], 1)];
            },
            proxy: true
          }], null, false, 4289264849)
        }), _c('Subpanel', {
          scopedSlots: _vm._u([{
            key: "header",
            fn: function fn() {
              return [_c('div', {
                staticClass: "title"
              }, [_vm._v("Confidential clients")])];
            },
            proxy: true
          }, {
            key: "main",
            fn: function fn() {
              return [_c('ConfidentialClientManager', {
                attrs: {
                  "title": 'Confidential Clients',
                  "mode": 'subsidiary',
                  "targetId": _vm.subsidiaryId
                }
              })];
            },
            proxy: true
          }], null, false, 1456634116)
        })], 1)];
      },
      proxy: true
    }], null, false, 3859528391)
  }) : _vm._e()], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }